<template>
	<main class="page-container container">

		<div class="section__top" :class="isMobileSmall ? 'mb-10' : 'mb-20'">
			<div class="section__top-left">
				<AppText
					:size="isMobileSmall ? 18 : isMobile ? 22 : 26"
					weight="600"
					:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
					class="font_Hsemibold"
				>
					{{ $t('contractNo') }} {{ data.numberContract }}
				</AppText>
			</div>
		</div>

		<div class="section__body mb-60">
			<div class="table-block mb-30">
				<table class="font_Hmedium fw-500">
					<thead>
					<tr>
						<th v-for="head in tableHeader" :key="head">
							{{ head }}
						</th>
					</tr>
					</thead>
					<tbody>
					<template v-if="data.productList.length">
						<tr v-for="(product, idx) in data.productList" :key="idx">
							<td class="text-nowrap">{{ product.id }}</td>
							<td class="text-nowrap">{{ product.planScheduleProductName | translate }}</td>
							<td class="text-nowrap">{{ product.quantity }}</td>
							<td class="text-nowrap">{{ product.measureName }}</td>
							<td class="fw-600 text-nowrap">{{ product.price | price }}</td>
							<td class="text-nowrap"></td>
						</tr>
					</template>

					<tr v-else>
						<td class='text-center' colspan="6">
							{{ $t('noData') }}
						</td>
					</tr>
					</tbody>
					<tfoot>
					<tr>
						<td colspan="6">
							<span class="font_Hsemibold fw-600">{{ $t('totalStartingCost') }}: {{ totalPrice }} </span>
						</td>
					</tr>
					</tfoot>
				</table>
			</div>

			<div class="flex-wrap ">
				<div class="mb-30">
					<ul class="purchase__documents">
						<li v-for="(file, idx) in documents" :key="idx">
							<a :href="baseUrl + file.file" target="_blank" download>
								<img src="@/assets/icons/document.svg" class="mr-10" :alt="file.name">
								{{ file.name }}
							</a>
						</li>
					</ul>
				</div>
				<div>
					<app-content-card padding-x="0" padding-y="0" :radius="[8, 8, 0, 0]">
						<app-tabs v-model="selectedTab" :list="tabItems" class="font_Hsemibold fw-600"/>
					</app-content-card>
					<template v-if="selectedTab === 'lotInfo'">
						<div class="table-block mt-30">
							<table class="tab-table">
								<tbody>
								<tr>
									<td class="text-nowrap font_Mmedium">{{ $t('deliveryTime') }} ({{
											$t('calendarTerm')
										}})
									</td>
									<td class="text-nowrap">{{ data.createDate | moment('DD.MM.YYYY') }}</td>
								</tr>
								<tr>
									<td class="text-nowrap font_Mmedium">{{ $t('deliveryAddress') }}</td>
									<td class="text-nowrap">{{ data.customerAddress }}</td>
								</tr>
								<tr>
									<td class="text-nowrap font_Mmedium">{{ $t('contractAmount') }}</td>
									<td class="text-nowrap">{{ data.price }}</td>
								</tr>
								<tr>
									<td class="text-nowrap font_Mmedium">{{ $t('contractNumber') }}</td>
									<td class="text-nowrap">{{ data.numberAttorney }}</td>
								</tr>
								<tr>
									<td class="text-nowrap font_Mmedium">{{ $t('contractDate') }}</td>
									<td class="text-nowrap">{{ data.dogovrDate | moment('DD.MM.YYYY') }}</td>
								</tr>
								<tr>
									<td class="text-nowrap font_Mmedium">{{ $t('productDetail') }} (
										{{ $t('workAndServices') }} )
									</td>
									<td class="text-nowrap"></td>
								</tr>
								</tbody>
							</table>
						</div>
					</template>
					<template v-if="selectedTab === 'customerInfo'">
						<div class="table-block mt-30">
							<table class="tab-table">
								<tbody>
								<tr>
									<td class="text-nowrap font_Mmedium">{{ $t('customerInn') }}</td>
									<td class="text-nowrap">{{ data.providerInn }}</td>
								</tr>
								<tr>
									<td class="text-nowrap font_Mmedium">{{ $t('region') }}</td>
									<td class="text-nowrap">{{ data.customerRegionName | translate }}</td>
								</tr>
								<tr>
									<td class="text-nowrap font_Mmedium">{{ $t('district') }}</td>
									<td class="text-nowrap">{{ data.customerDistrictName | translate }}</td>
								</tr>
								<tr>
									<td class="text-nowrap font_Mmedium">{{ $t('address') }}</td>
									<td class="text-nowrap">{{ data.customerAddress }}</td>
								</tr>
								<tr>
									<td class="text-nowrap font_Mmedium">
										{{ $t('customersPersonalAccountWithTheTreasury') }}
									</td>
									<td class="text-nowrap"></td>
								</tr>
								</tbody>
							</table>
						</div>
					</template>
					<template v-if="selectedTab === 'executorInfo'">
						<div class="table-block mt-30">
							<table class="tab-table">
								<tbody>
								<tr>
									<td class="text-nowrap font_Mmedium">{{ $t('region') }}</td>
									<td class="text-nowrap">{{ data.providerRegionName | translate }}</td>
								</tr>
								<tr>
									<td class="text-nowrap font_Mmedium">{{ $t('district') }}</td>
									<td class="text-nowrap">{{ data.providerDistrictName | translate }}</td>
								</tr>
								<tr>
									<td class="text-nowrap font_Mmedium">{{ $t('address') }}</td>
									<td class="text-nowrap">{{ data.customerAddress }}</td>
								</tr>
								</tbody>
							</table>
						</div>
					</template>
				</div>
			</div>
		</div>

	</main>
</template>
<script>
import BlockWrap from "../../../components/shared-components/BlockWrap";
import AppText from "../../../components/shared-components/AppText";
import DynamicIcon from "../../../components/shared-components/DynamicIcon";
import AppButton from "../../../components/shared-components/AppButton";
import ClientService from '@/services/api/client-service';
import AppTabs from '@/components/shared-components/AppTabs';
import AppContentCard from '@/components/shared-components/AppContentCard';

export default {
	components: {
		AppButton,
		DynamicIcon,
		AppText,
		BlockWrap,
		AppTabs,
		AppContentCard
	},
	data() {
		return {
			data: {
				productList: []
			},
			id: null,
			tableHeader: [
				"№",
				this.$t('productName'),
				this.$t('amountShort'),
				this.$t('unitShort'),
				this.$t('contractAmount'),
				this.$t('startingAmount'),
			],
			tabItems: [
				{
					name: this.$t('lotInformation'),
					value: 'lotInfo'
				},
				{
					name: this.$t('customerInformation'),
					value: 'customerInfo'
				},
				{
					name: this.$t('performerInformation'),
					value: 'executorInfo'
				}
			],
			selectedTab: 'lotInfo',
			baseUrl: process.env.VUE_APP_BASE_URL_DOMAIN
		};
	},
	created() {
		this.getId();
		this.getDetails();
	},
	methods: {
		getId() {
			if (this.$route.params.id) {
				this.id = +this.$route.params.id;
			}
		},
		getDetails() {
			ClientService.getDirectPurchaseById(this.id)
				.then(response => {
					this.data = response.data.result;
				}, error => this.$notify({type: 'error', text: error}));
		}
	},
	computed: {
		totalPrice() {
			return this.data.price;
		},
		documents() {
			if (!this.data.fIles) {
				return;
			}
			let files;
			if (this.data.fIles.includes(';')) {
				files = this.data.fIles.split(';');
			}

			const result = []
			if (files && files[0] === 'null') {
				files.shift();
			}
			if (files) {
				files.forEach(file => {
					const name = (file.split('/'))[1].split('__');
					result.push({
						name: name[name.length - 1],
						file: file
					});
				});
			}

			return result;
		}
	}
};
</script>
<style lang="scss" scoped>
@import "../../../assets/styles/mixins";

ul.purchase__documents {
	li {
		a {
			text-decoration: underline;
			color: cornflowerblue;
			font-size: 14px;

			img {
				width: 14px;
				height: 18px;
			}
		}
	}
}

.table-block {
	table {
		tfoot {
			tr {
				td {
					border-top: 1px solid #E5EFF5;
					background-color: #E9F9F0;
					text-align: center;

					span {
						color: #27AE60;
						font-weight: 600;
					}
				}
			}
		}
	}
}

.tab-table {
	tr {
		td {
			&:first-child {
				width: 25%;
			}
		}
	}
}
</style>
